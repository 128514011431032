<template>
  <hit-base-input
    :disabled="disabled"
    :full-width="adaptToContent"
    :inline-input="inlineInput"
    :label="label"
    :row-label="rowLabel"
    :validation-state="validationState"
    use-custom-height
  >
    <OnClickOutside @trigger="handleClickOutside">
      <div
        :ref="(el) => (inputDomEl = el)"
        :class="{
          'w-full': !adaptToContent || fullWidth,
          'xl:w-1/2': !adaptToContent && !fullWidth,
          'border rounded': bordersVisible,
          'border-input':
            !isFocussed &&
            ((valid && pristine) || !validationState || (valid && dirty)),
          'border-danger': !isFocussed && invalid && dirty && bordersVisible,
          'border-input-focus': isFocussed,
        }"
        class="inline-block bg-input h-full text-input focus:border-input-focus focus:ring-transparent focus:outline-none"
      >
        <div
          :class="{
            clear: clearable,
          }"
          class="hit-select h-full"
        >
          <div
            :class="{clear: clearable}"
            class="flex flex-row items-center justify-center hit-select h-full"
          >
            <div
              :class="{
                'border-padding': bordersVisible,
              }"
              class="w-full inline-grid items-center"
            >
              <div
                :class="{
                  'text-input': value,
                  'text-input-light': !value,
                }"
                :disabled="disabled"
                class="w-full hit-select-input truncate flex items-center gap-2"
              >
                <input
                  ref="input"
                  class="bg-input w-full text-left border-transparent"
                  :class="{
                    'cursor-not-allowed': disabled,
                    'cursor-text': !disabled,
                  }"
                  :disabled="disabled"
                  :readonly="readonly"
                  :value="inputValue"
                  :placeholder="
                    placeholder || t('hit-components.common.search')
                  "
                  @keyup.stop="debounceInput"
                  @keyup.up.stop="handleArrowUp"
                  @keyup.down.stop="handleArrowDown"
                  @keyup.enter.stop="handleEnterKey"
                  @change.stop
                  @input="storeLastValue"
                  @focus.prevent="onFocus"
                  @blur.prevent="onBlur"
                >
              </div>
            </div>
            <slot
              name="fastModeBrowser"
              :input-value="inputValue"
            />
            <div
              v-if="!disabled"
              class="h-full text-center items-center flex flex-row"
            >
              <div class="w-8 px-1 h-full flex flex-row">
                <hit-icon
                  v-if="isDropdownVisible"
                  icon="expand-top"
                  clickable
                  @click="closeDropdown"
                />
                <hit-icon
                  v-else
                  icon="expand-bottom"
                  clickable
                  @click.stop="openDropdown"
                />
              </div>
              <div
                v-if="clearable && valueToDisplay"
                class="w-8 px-1 h-full flex flex-row border-l border-input"
              >
                <hit-icon
                  icon="clear"
                  clickable
                  @click.stop="clearInput"
                />
              </div>
              <div
                v-if="!staticValues && searchable"
                class="w-8 px-1 h-full flex flex-row border-l border-input"
              >
                <hit-icon
                  icon="search"
                  class="justify-center items-center"
                  clickable
                  @click.stop="openModalBrowser"
                />
              </div>
              <div
                v-if="showRedirectIcon"
                class="w-8 px-1 h-full flex flex-row border-l border-input"
              >
                <hit-icon
                  icon="go_to"
                  class="justify-center items-center"
                  clickable
                  @click.stop="redirectToDetailPage"
                />
              </div>
            </div>
          </div>
          <div class="relative">
            <Teleport to="#root-element">
              <div
                v-if="entityOptions.length > 0"
                :style="dropdownStyle"
                :class="{
                  'max-h-0': !(isDropdownVisible && isFocussed),
                  'max-h-full': isDropdownVisible && isFocussed,
                  'overflow-y-auto': true,
                }"
                class="absolute shadow rounded overflow-y-auto z-40 inline-block w-auto bg-input transition-on-open"
              >
                <div
                  v-for="(item, itemId) in entityOptions"
                  :id="'select-item-' + itemId"
                  :key="itemId"
                  class="cursor-pointer w-full border-accent text-input select-item"
                  @click.stop="onSelect(item)"
                >
                  <div
                    class="flex w-full items-center p-2 relative hover:bg-input-hover hover:text-white hover:border-accent hit-select-item"
                    :class="{
                      'bg-input-hover text-white':
                        itemId === preselectedItemIndex,
                      'border-l-2 border-input-focus':
                        item.id === valueToDisplay?.id,
                      'pl-2': item.id !== valueToDisplay?.id,
                    }"
                  >
                    <div class="w-full items-center flex">
                      <div
                        class="mx-2 leading-6 grid grid-flow-col gap-2 break-words whitespace-normal"
                      >
                        <slot
                          :item="item"
                          name="itemDesignation"
                        >
                          {{ extractStringToDisplay(item) }}
                        </slot>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                :style="dropdownStyle"
                :class="{
                  'max-h-0': !(isDropdownVisible && isFocussed),
                  'max-h-full': isDropdownVisible && isFocussed,
                  'overflow-y-auto': true,
                }"
                class="absolute shadow rounded overflow-y-auto z-40 inline-block w-auto bg-input transition-on-open"
              >
                <div
                  class="flex w-full items-center p-2 pl-2 relative hover:bg-input-hover hover:text-white hover:border-accent hit-select-item"
                >
                  <div class="w-full items-center flex">
                    <div
                      class="mx-2 leading-6 grid grid-flow-col gap-2 break-words whitespace-normal text-input"
                    >
                      {{ t('hit-components.common.no-matching-result') }}
                    </div>
                  </div>
                </div>
              </div>
            </Teleport>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </hit-base-input>
</template>

<script>
import HitIcon from '../../icon/HitIcon.vue';
import HitBaseInput from './HitBaseInput.vue';
import {OnClickOutside} from '@vueuse/components';
import HitSelectMixin from '../../../mixins/form/HitSelectMixin';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';
import {HitUtils} from '../../../utils/hit/HitUtils';
import HitBreakpointsMixin from '../../../mixins/breakpoints/HitBreakpointsMixin';

const MAX_ELEMENTS_DISPLAYED = 25;

export default {
  name: 'HitFastModeBrowser',
  components: {
    HitBaseInput,
    HitIcon,
    OnClickOutside,
  },
  mixins: [HitSelectMixin, HitFormValidationMixin, HitBreakpointsMixin],

  setup() {
    const {t} = useI18n();
    return {t};
  },

  props: {
    /**
     * Input label that is displayed in front/top of the input
     */
    label: {
      type: [String, Object],
      required: false,
    },

    /**
     * Enable readonly for the input
     */
    readonly: {
      type: Boolean,
      default: false,
    },

    /**
     * Enable inline mode for the input
     */
    inlineInput: {
      type: Boolean,
      default: false,
    },

    /**
     * Flag to disable the input -> no modifications are allowed
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Flag to enable / disable the cancel icon in the input element
     */
    displayCancelIcon: {
      type: Boolean,
      required: false,
      default: false,
    },

    entityClass: {
      type: Function, // JS classes are special functions,
      required: true,
    },

    /**
     * If we want to add a supplementary filter to the options request
     */
    customFilters: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    /**
     * When set to true, filters the entities on the active flag
     */
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: true,
    },

    /**
     * If the values are not loaded from the database but are a list of static values
     */
    staticValues: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * Activates the search icon (only activate for HitContainerFastModeBrowser)
     */
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * When setting this flag to true, it emits the selected item to the parent
     * component instead of calling handleChange of the container
     */
    emitChangeToParent: {
      type: Boolean,
      required: false,
      default: false,
    },

    propertyPathToDisplay: {
      type: Array,
      required: false,
      default: () => {
        return ['fullDesignation'];
      },
    },

    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },

    activateRedirection: {
      type: Boolean,
      required: false,
      default: true,
    },

    customAttributes: {
      type: String,
      required: false,
      default: '',
    },

    // We need to pass the dataservice like this otherwise import error
    dataService: undefined,
  },

  data() {
    return {
      inputValue: '',
      lastValue: '',
      valueToDisplay: undefined,
      inputDomEl: undefined,
      debounceTimer: null,
      inputBottomY: 0,
      inputTopY: 0,
      inputLeftX: 0,
      inputRightX: 0,
      isDropdownVisible: false,
      entityOptions: [],
      preselectedItemIndex: null,
      isFocussed: false,
    };
  },

  computed: {
    showRedirectIcon() {
      return (
        this.activateRedirection &&
        this.entityClass?.detailPageRoute &&
        this.valueToDisplay?.id
      );
    },
    dropdownStyle() {
      let result = {};
      if (!this.inputDomEl) {
        return result;
      }
      let itemHeight = 40;
      let dropdownSize = this.entityOptions.length * itemHeight;

      let limitTop = 0;
      if (this.responsiveBreakpointHeightMd) {
        limitTop = 120; //120 is rough position of panel, which cuts off the dropdown,
      }
      let dropdownTooLargeForBottom =
        this.inputBottomY + dropdownSize > window.innerHeight;
      let dropdownTooLargeForTop = this.inputTopY - dropdownSize < limitTop;
      let bottomLimitToSwitchToScrollUpwards = 5 * itemHeight;
      let enableScrollbar = dropdownTooLargeForBottom && dropdownTooLargeForTop;
      let scrollUpwards =
        this.inputBottomY + bottomLimitToSwitchToScrollUpwards >
        window.innerHeight;
      let dropdownGoesUp =
        (dropdownTooLargeForBottom && !dropdownTooLargeForTop) ||
        (enableScrollbar && scrollUpwards);

      let spaceBetweenInputRightSideAndRightScreenEdge =
        window.innerWidth - this.inputRightX;
      let spaceBetweenInputLeftSideAndRightScreenEdge =
        window.innerWidth - this.inputLeftX;
      let spaceBetweenInputRightSideAndLeftScreenEdge = this.inputRightX;
      // width of dropdown should be around 200-250 but if one element is long it might be more, so it's better to account
      // for 300 I think. Any larger is a problem anyway since the design should work for a minimum screen width of 320
      let enoughSpaceLeftAligned =
        spaceBetweenInputLeftSideAndRightScreenEdge > 300;
      let enoughSpaceRightAligned =
        spaceBetweenInputRightSideAndLeftScreenEdge > 300;

      let alignDropDownRight =
        !enoughSpaceLeftAligned &&
        (enoughSpaceRightAligned ||
          spaceBetweenInputRightSideAndLeftScreenEdge >
            spaceBetweenInputLeftSideAndRightScreenEdge);

      if (alignDropDownRight) {
        result.right = spaceBetweenInputRightSideAndRightScreenEdge + 'px';
      } else {
        result.left = this.inputLeftX + 'px';
      }

      let height;
      if (dropdownGoesUp) {
        result.bottom = this.inputBottomY - this.inputTopY - 2 + 'px';
        height = this.entityOptions.length * itemHeight;
        result.height = height + 'px';
      } else {
        result.top = this.inputBottomY + 'px';
      }
      if (enableScrollbar) {
        result.overflow = 'auto';
        if (scrollUpwards) {
          height = this.inputTopY - limitTop;
        } else {
          height = window.innerHeight - this.inputBottomY - 16;
        }
        result.height = height + 'px';
      }
      if (dropdownGoesUp) {
        result.top = this.inputTopY - height - 1 + 'px';
      }
      return result;
    },
  },

  methods: {
    onFocus() {
      this.isFocussed = true;
    },

    onBlur() {
      if (this.responsiveBreakpointSm) {
        this.isFocussed = false;
        if (!this.value) {
          this.clearInput();
        } else {
          this.validateOrParseEntity();
        }
      }
    },
    storeLastValue($event) {
      this.inputValue = $event.target.value;
      this.lastValue = $event.target.value;
    },
    /**
     * To avoid a big number of requests, we do wait 250ms without keystroke to
     * fetch the available entity options from the database
     */
    debounceInput($event) {
      if (['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].includes($event.key)) return;
      this.preselectedItemIndex = null;
      this.inputValue = $event.target.value;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.fetchOptions($event.target.value);
      }, 250);
    },

    /**
     * Fetches the first 20 records from the database that satisfy the filter
     */
    fetchOptions(inputVal) {
      this.updateInputPosition();
      const delimiters = /[ .,]+/;
      const filterParts = inputVal
        .split(delimiters)
        .filter((item) => item && item !== '-' && item !== '.');
      if (!this.staticValues) {
        this.fetchDbOptions(filterParts);
      } else {
        this.filterStaticValues(filterParts);
      }
    },

    /**
     * Load filtered values from database
     */
    fetchDbOptions(filterParts) {
      let filters = _.cloneDeep(this.customFilters) || {};
      if (
        this.onlyLoadActiveRecords &&
        'active' in this.entityClass.allAttributes
      ) {
        filters['active'] = 'is.true';
      }
      if (filterParts.length > 0) {
        if (!('and' in filters)) filters['and'] = [];
        filterParts.forEach((p) => {
          filters['and'].push(`search.ilike.*${p}*`);
        });
      }
      const orderAtt = this.entityClass.allAttributes[
        this.entityClass.orderKey
      ];
      const orderColumn = orderAtt?.column || this.entityClass.orderKey;
      let attributes = this.entityClass.entityColumns();
      if (this.customAttributes) {
        attributes += ',' + this.customAttributes;
      }
      this.dataService
        .read(this.entityClass.apiRoute, {
          attributes: attributes,
          filters: HitUtils.filtersToString(filters),
          limit: MAX_ELEMENTS_DISPLAYED,
          order: `${orderColumn}.asc`,
        })
        .then((res) => {
          this.entityOptions = res.data.map(
            (entity) => new this.entityClass(entity)
          );
          this.updateInputPosition();
        });
      this.isDropdownVisible = true;
    },

    /**
     * Filter the list of static values on the input values
     */
    filterStaticValues(filterParts) {
      const options = [];
      this.staticValues.forEach((val) => {
        if (
          filterParts.every((sub) =>
            this.extractStringToDisplay(val)
              .toLowerCase()
              .includes(sub.toLowerCase())
          )
        ) {
          options.push(val);
        }
      });
      this.entityOptions = options;
      this.isDropdownVisible = true;
    },

    /**
     * Event handler when the user presses the arrow up key -> select previous item
     */
    handleArrowUp() {
      this.preselectedItemIndex > 0 && !(this.preselectedItemIndex === null)
        ? this.preselectedItemIndex--
        : (this.preselectedItemIndex = this.entityOptions.length - 1);
    },

    /**
     * Event handler when the user presses the arrow down key -> select next item
     */
    handleArrowDown() {
      this.preselectedItemIndex === this.entityOptions.length - 1 ||
      this.preselectedItemIndex === null
        ? (this.preselectedItemIndex = 0)
        : this.preselectedItemIndex++;
    },

    /**
     * Event handler when the user presses the arrow key -> selects the preselected item
     * When no item is preselected and there is only one option -> it selects this item
     */
    handleEnterKey() {
      if (this.preselectedItemIndex === null) {
        if (this.entityOptions.length === 1) {
          this.onSelect(this.entityOptions[0]);
        }
      } else {
        this.onSelect(this.entityOptions[this.preselectedItemIndex]);
      }
    },

    /**
     * The user has the possibility to pass an object if the entity class or to
     * pass the ID of the entity.
     * The object is preferred to avoid a supplementary request at mounting
     */
    validateOrParseEntity() {
      if (!this.value) {
        this.valueToDisplay = null;
        this.inputValue = '';
      } else if (this.value instanceof this.entityClass) {
        this.valueToDisplay = this.value;
        this.inputValue = this.extractStringToDisplay(this.value);
      } else {
        this.valueToDisplay = new this.entityClass(this.value);
        this.inputValue = this.extractStringToDisplay(this.valueToDisplay);
      }
    },

    /**
     * Emits the selected item. Can be triggered via a mouse click or via the enter click
     */
    onSelect(item) {
      if (!this.disabled) {
        if (this.emitChangeToParent) {
          this.$emit('item-selected', item);
        } else {
          this.fireInputChange(item);
        }
        this.closeDropdown();
      }
    },

    /**
     * Used to know where the input needs to be displayed
     */
    updateInputPosition() {
      if (this.inputDomEl) {
        let rect = this.inputDomEl.getBoundingClientRect();
        this.inputBottomY = rect.bottom;
        this.inputTopY = rect.top;
        this.inputLeftX = rect.left;
        this.inputRightX = rect.right;
      }
    },

    /**
     * Clears the input and closes the dropdown when the user clicks on the clear icon
     */
    clearInput() {
      this.inputValue = '';
      this.valueToDisplay = null;
      this.isDropdownVisible = false; // Do not use closeDropdown()
      this.fireInputChange(null);
    },

    /**
     * Emits the search event to the parent component because we can only create
     * a search browser in the HitContainerFastModeBrowser component
     */
    openModalBrowser() {
      this.$emit('browse', this.lastValue);
      this.validateOrParseEntity();
      this.closeDropdown();
    },

    /**
     * Opens the dropdown and focuses the input
     */
    openDropdown() {
      this.inputValue = '';
      if (this.responsiveBreakpointSm) {
        this.$refs.input.focus();
      }
      this.isFocussed = true;
      this.fetchOptions(this.inputValue);
      this.isDropdownVisible = true;
    },

    /**
     * Closes the dropdown with the animation
     */
    closeDropdown() {
      this.isDropdownVisible = false;
      this.validateOrParseEntity();
      this.isFocussed = false;
    },

    /**
     * When the user clicks outside the dropdown, it is closed
     */
    handleClickOutside() {
      if (!this.isDropdownVisible) return;
      if (!this.inputValue && this.responsiveBreakpointSm) {
        this.fireInputChange(null);
        this.closeDropdown();
      }
    },

    extractStringToDisplay(value) {
      try {
        let string = value;
        this.propertyPathToDisplay.forEach((p) => {
          string = string[p];
        });
        return string;
      } catch {
        return null;
      }
    },

    redirectToDetailPage() {
      this.$router.push({
        name: this.entityClass.detailPageRoute,
        params: {id: this.valueToDisplay?.id},
      });
    },
  },

  watch: {
    value() {
      this.validateOrParseEntity();
    },
    isDropdownVisible(newValue, oldValue) {
      this.enableScroll = false;
      let canvasDOM = document.getElementById('content-body-container');
      let tableDOMs = document.getElementsByClassName('hit-table-content');
      if (!canvasDOM && tableDOMs.length === 0) {
        this.updateInputPosition();
        return;
      }
      if (newValue === true && oldValue === false) {
        canvasDOM.addEventListener('scroll', this.updateInputPosition);
        tableDOMs.forEach((tableDOM) => {
          tableDOM.addEventListener('scroll', this.updateInputPosition);
        });
        setTimeout(this.updateInputPosition, 1000);
      } else if (newValue === false && oldValue === true) {
        canvasDOM.removeEventListener('scroll', this.updateInputPosition);
        tableDOMs.forEach((tableDOM) => {
          tableDOM.removeEventListener('scroll', this.updateInputPosition);
        });
      }
      setTimeout(() => {
        this.enableScroll = true;
      }, 350);
    },
  },

  beforeMount() {
    this.validateOrParseEntity();
  },
};
</script>

<style scoped>
input {
  padding: 0;
  border: 0px solid transparent;
}
</style>
