<template>
  <hit-fast-mode-browser
    :searchable="searchable"
    :label="labelToDisplay"
    :entity-class="entityClass"
    :value="value"
    :options="null"
    :data-service="DataService"
    :static-values="staticValues"
    :custom-filters="customFilters"
    :validation-state="validate ? container.validationState[attribute] : null"
    @change="handleChange"
    @browse="openBrowser"
  >
    <template #fastModeBrowser>
      <hit-dynamic-browser
        v-if="showEntityBrowser"
        visible
        :init-search-value="initBrowseValue"
        :browser-type="HitUtils.snakeToCamel(entityClass.apiRoute)"
        :custom-filters="customFilters"
        @cancel="showEntityBrowser = false"
        @item-selected="handleChange"
      />
    </template>
  </hit-fast-mode-browser>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {HitFastModeBrowser} from '@hit/components';
import {DataService} from '../../../api';
import HitDynamicBrowser from './HitDynamicBrowser.vue';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
import {ATTRIBUTE_TYPES} from '../../container';
import {BaseModel} from '../../../models';

export default {
  name: 'HitContainerFastModeBrowser',
  components: {HitFastModeBrowser, HitDynamicBrowser},
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    /**
     * Wrapping container of this input that contains all the data about the attribute
     */
    container: {
      type: Object,
      required: true,
    },

    /**
     * Name of the container attribute the input belongs to
     */
    attribute: {
      type: String,
      required: true,
    },

    validate: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Per default = header from tableProperties from the attribute.
     * Set this label to overwrite the label defined for the attribute
     */
    label: {
      type: String,
      required: false,
      default: null,
    },

    /**
     * Allows you to filter your search, for example
     */
    customFilters: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },

    /**
     * If the values are not loaded from the database but are a list of static values
     */
    staticValues: {
      type: Array,
      required: false,
      default: null,
    },

    /**
     * Activates the search icon in the search bar
     */
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Set this flag to hide the label in case of a group input where the label
     * of the first input needs to be passed to the group input component
     */
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * When setting this flag to true, it emits the selected item to the parent
     * component instead of calling handleChange of the container
     */
    emitChangeToParent: {
      type: Boolean,
      required: false,
      default: false,
    },

    forceDataObjectClass: {
      type: Function,
      required: false,
      default: null,
    },

    /**
     * In the form data, we use the column name as key because this name is
     * persistent. The name of the attributes risks to be changed during
     * implementation of a new function -> IS SAFER WITH DB COLUMNS
     */
    transformSelectedItemToDbColumns: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * In some cases, we want to check the content of a selected record before
     * triggering the update event
     * When function returns false, update is skipped
     * (Example: staff used in injection form needs to have a cost_resource_id)
     */
    browserSelectValidation: {
      type: Function,
      required: false,
      default() {
        return true;
      },
    },
  },

  data() {
    return {
      showEntityBrowser: false,
      initBrowseValue: null,
    };
  },

  computed: {
    HitUtils() {
      return HitUtils;
    },
    DataService() {
      return DataService;
    },
    /**
     * The entity class that is passed to the fast mode browser depends on the
     * datatype of the container attribute
     */
    entityClass() {
      if (this.staticValues) {
        return this.staticValues[0].constructor;
      } else if (this.forceDataObjectClass) {
        return this.forceDataObjectClass;
      } else if (this.container && this.container.attributes[this.attribute]) {
        return this.container.attributes[this.attribute].dataObjectClass;
      } else {
        throw Error(
          `Entity class must be defined to use the fast mode browser for attribute ${this.attribute}`
        );
      }
    },

    /**
     * DEFAULT = header from tableProperties from attribute
     * If label prop is set -> overwrites the default value
     */
    labelToDisplay() {
      if (this.hideLabel) return null;
      if (this.label) return this.label;
      if (this.container && this.container.attributes[this.attribute]) {
        const att = this.container.attributes[this.attribute];
        return this.t(att?.tableProperties?.header ?? '');
      }
      return '';
    },

    /**
     * Actual value of the attribute
     */
    value() {
      if (this.container && this.container.data[this.attribute]) {
        return this.container.data[this.attribute];
      }
      return null;
    },
  },

  methods: {
    openBrowser(inputValue) {
      this.initBrowseValue = inputValue;
      this.showEntityBrowser = true;
    },
    handleChange(data) {
      if (data !== null && !(data instanceof this.entityClass)) {
        return;
      }
      try {
        if (this.browserSelectValidation) {
          const result = this.browserSelectValidation({
            item: data,
            route: this.entityClass.apiRoute,
          });
          if (!result) return;
        }
      } catch (e) {
        console.error('Error during browser validations', e);
      }
      this.showEntityBrowser = false;
      const dataToSave = this.transformSelectedItemToDbColumns
        ? this.transformToDbColumns(data, this.entityClass)
        : data;
      if (this.emitChangeToParent) {
        this.$emit('itemSelected', dataToSave);
      } else {
        this.container.handleChange(this.attribute, dataToSave);
      }
    },

    transformToDbColumns(data, dataClass) {
      if (!data) return {};
      const dataToStore = {};
      if ('id' in data) {
        dataToStore['id'] = data['id'];
      }
      const attributes = dataClass.allAttributes;
      dataClass.attrsToStoreInForm.forEach((attrKey) => {
        if (attrKey.includes('designation')) {
          dataToStore['designation'] = data?.designation;
        } else if (attrKey.includes('description')) {
          dataToStore['description'] = data?.description;
        } else {
          const a = attributes[attrKey];
          const storeKey = a?.formItemName || a?.column || attrKey;
          if (a.dataType > 100) {
            dataToStore[storeKey] = this.transformToDbColumns(
              data[attrKey],
              a.dataObjectClass
            );
          } else {
            dataToStore[storeKey] = data?.[attrKey];
          }
        }
      });
      return dataToStore;
    },
  },

  emits: ['itemSelected'],
};
</script>
